import { Component, Input, inject, type OnInit } from '@angular/core';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';

import { UserInfo } from '../../models/user';
import { UserActivityService } from '../../services/user-activity.service';

@Component({
  selector: 'user-logo',
  templateUrl: './user-logo.component.html',
  styleUrls: ['./user-logo.component.scss'],
  standalone: true,
  imports: [AssetSrcDirective],
})
export class UserLogoComponent implements OnInit {
  private readonly activityService = inject(UserActivityService);
  @Input() user: UserInfo;
  @Input() hideStatus = false;
  @Input() logoUrl: string;

  public logo: string | undefined;

  public get isActive(): boolean {
    return this.userId && this.activityService.getActiveStatus(this.userId);
  }

  public get isFavorite(): boolean {
    return this.userId && this.activityService.getFavoriteStatus(this.userId);
  }

  private get userId(): number {
    return this.user.id || this.user.userId || this.user.associatedUserId;
  }

  public ngOnInit(): void {
    this.logo = this.getLogoUrl();
  }

  public getLogoUrl(): string {
    const version = this.user?.logoSettings?.modifiedAt || '';

    if (this.logoUrl) return `${this.logoUrl}${version ? `?v=${version}` : ''}`;

    const logoUrl = this.userId && this.activityService.getLogoUrl(this.user);
    return logoUrl && `${logoUrl}${version ? `?v=${version}` : ''}`;
  }

  public getLogoPlaceholder(): string {
    if (!this.user) {
      return '';
    }

    if (this.user.fullName) {
      return `${this.user.fullName[0]}`.toUpperCase();
    }

    return `${this.user.firstName ? this.user.firstName[0] : ''}${
      this.user.lastName ? this.user.lastName[0] : ''
    }`.toUpperCase();
  }
}
