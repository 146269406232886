@if (logo) {
  <img [src]="logo" />
}
@if (!logo) {
  <div class="user-logo_placeholder">
    <span>{{ getLogoPlaceholder() }}</span>
  </div>
}
@if (!hideStatus) {
  <div
    class="user-logo_online-status"
    [class.online]="isActive"
  ></div>
}
@if (!hideStatus && isFavorite) {
  <div class="user-logo_favorite-status">
    <img appAssetSrc="assets/svg/common/favorite.svg" />
  </div>
}
